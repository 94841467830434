<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Statut spécifique" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un statut spécifique</div>
              </v-row>
            </v-card-title>

            <!-- l'app -->
            <v-card-text>
              <v-row no-gutters align="center" class="my-3">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">application</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <div >{{ appName }}</div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'ordre -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">ordre</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'ordre"
                    dense
                    v-model="ranking"
                    :rules="[rules.noDoubleRanking,]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La clé technique -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">clé technique</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="La clé technique"
                    dense
                    v-model="tid"
                    :rules="[rules.noDoubleTid,]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>           

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                :loading="loading"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Alert désassociation statut spécifiques -->
    <StandardDialogConfirmed
    title="Confirmez votre action"
    label= "L'application est associée aux statuts par défaut. 
    <br/> En créant ce statut spécifique, l'application n'aura qu'un seul statut."
    :labelHtml="true"
    :visible.sync="showStandardDialog"
    @confirmed="onSaveSpecificStatus()" >
    </StandardDialogConfirmed>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import StandardDialogConfirmed from "@/components/ui/StandardDialogConfirmed.vue";

import { TicketingStatusService } from "@/service/conf/ticketing_status_service.js";
import { ApplicationService } from "@/service/dictionary/applications_service.js";
import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddTicketingStatus",
  components: { Workflow, TitleAndReturnComponent, StandardDialogConfirmed, },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      /** le service des applications */
      serviceApplication: null,
      /** Le service des applications ticketing */
      serviceAppTicketing: null,

      /** le nom de l'application */
      appName: null,
      /** le label */
      label: null,
      /** l'ordre */
      ranking: null,
      /** la clé technique */
      tid: null,

      /** id de l'application  */
      entityAppId: null,

      /** l'application à qui on doit ajouter un statut  */
      application: null,

      /** Les status de l'application */
      appStatus: [],

      /** l'ensemble des statut par défaut */
      defaultStatus: [],
      
      /** rendre visible ou non la dialog de warning save */
      showStandardDialog: false,

      /** Les règles de saisie des composants */
      rules: {
        noDoubleRanking: value => !this.checkAlreadyHasRanking(value) || "Ce rang est déjà utilisé pour un autre statut.",
        noDoubleTid: value => !this.checkAlreadyHasTid(value) || "Cette clé technique existe déjà pour un statut de l'app.",
      }
    };
  },
  methods: {

    async load() {
      console.error("load ");
      console.error("application id " + this.entityAppId + " type " + typeof this.entityAppId);

      try {
        this.loading = true;

        // Récupération de l'application cible
        this.application = await this.serviceApplication.getById(this.entityAppId);
        this.appName = this.application.label;

        // Récupération de l'ensemble des statuts de cette app
        this.appStatus = await this.serviceAppTicketing.getAllStatusOfApplication(this.entityAppId);
        // Récupération de l'ensemble des statuts par défaut
        this.defaultStatus = await this.service.getAllDefaultStatus();
        
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    // enregistrement 
    async save() {
      // application as des statut par défaut --> demande de confirmation
      if (this.checkAppHasDefaultStatus()) {
        // désassociation avec tout les statuts par défaut
        this.showStandardDialog = true;
      } else {
        this.createSpecificStatus();
      } 
    },

    /** Méthode de traitement de la désassociation de l'application avec ses statut par défaut
     * puis création du statut
     */
    async onSaveSpecificStatus() {
      try {
        this.loading = true;

        // Parcours de tous les statuts de l'application et les désassociation
        for (let defStatus of this.appStatus) {
          await this.serviceAppTicketing.disassociateStatusOfApp(this.entityAppId, defStatus.id);
        }

        this.createSpecificStatus();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "désassociation du statut : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Méthode de création du statut spécifique */
    async createSpecificStatus() {
      try {
        this.loading = true;

        let entity = {};
        entity.label = this.label;
        entity.ranking = this.ranking;
        entity.tid = this.tid;

        // Créer et associe le nouveau statut à l'app
        await this.serviceAppTicketing.createSpecificStatutToApp(this.entityAppId, entity);

        this.$router.go(-1);

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement du statut : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }

    },



    /** Analyse si l'application a les status par défaut ou non */
    checkAppHasDefaultStatus() {
      let statutDefault = true;

      if (this.appStatus && this.defaultStatus) {
        // Parcours la liste des status de l'application 
        for (let status of this.appStatus) {
          // Tente de trouver le statut par défaut correspondant
          let found = this.defaultStatus.find((d) => d.id == status.id);

          if (!found) {
            statutDefault = false;
            break;
          }
        }
      }

      return statutDefault;
    },

    /** Check si un des statut de l'app contient déjà le rank */
    checkAlreadyHasRanking() {
      // Analyse si l'app as les statut par défaut.
      if (this.checkAppHasDefaultStatus()) {
        return false;
      } else {
        // L'app utilise les statut spécifiques, on recherche le rank saisie
        let found = this.appStatus.find((a) => a.ranking.toString() === this.ranking);
        
        // La rank saisi est trouvé, affichage d'une erreur
        if (found) {
          return true;
        } else {
          return false;
        }
      }
    },

    /**  Check si un des statut de l'app contient la même clé technique */
    checkAlreadyHasTid() {
      // Analyse si l'app as les statut par défaut
      if (this.checkAppHasDefaultStatus()) {
        return false;
      } else {
        // L'app utilise les statuts spécifiques, on check le tid
        let found = this.appStatus.find((a) => a.tid == this.tid);

        if (found) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.label || this.label == "") {
          return false;
        }
        if (!this.ranking || this.ranking == "" || this.checkAlreadyHasRanking()) {
          return false;
        }

        let changed = false;

        if (this.entityHasChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    
    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.label) {
        changed = true;
      }
      if (this.ranking) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new TicketingStatusService(this.$api.getTicketingStatus());
    this.serviceApplication = new ApplicationService(this.$api.getApplicationApi());
    this.serviceAppTicketing = new TicketingObjectSubobjectService(
      this.$api.getTicketingObjectSubobject()
    );


    // Récupération des paramètres
    if (this.$route.query) {
      if (this.$route.query.app) {
        this.entityAppId = parseInt(this.$route.query.app);
      }
    }
    
    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>